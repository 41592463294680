module.exports = {
  siteTitle: "Southend Solutions", // <title>
  shortSiteTitle: "Southend Solutions", // <title> ending for posts and pages
  siteDescription: "Southend Solutions, Cloud Expertise in the Carolinas",
  siteUrl: "https://southendsolutions.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Southend Solutions",
  authorTwitterAccount: "",

  /* info */
  headerTitle: "Southend Solutions",
  headerSubTitle: "Cloud Expertise in the Carolinas",

  /* manifest.json */
  manifestName: "Southend Solutions Home Page",
  manifestShortName: "SES HP", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  gravatarImgMd5: "https://s.gravatar.com/avatar/298503303d1cfd8bf5702d3745bc01aa",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/southendsolutions" }
  ]
};
